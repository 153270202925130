<template>
  <div class="loading-box" ref="loading" v-show="isShow"></div>
</template>
<script>
import lottie from "static/js/lottie_light.min.js";
export default {
  props: ["loading"],
  computed: {
    isShow() {
      if (this.loading == undefined || this.loading) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    let _this = this;
    lottie.loadAnimation({
      container: _this.$refs.loading, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "static/js/loading.json" // the path to the animation json
    });
  }
};
</script>
<style>
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
.loading-box svg {
  width: 96px !important;
  height: 96px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
</style>


